import { default as accountiPk3eaoYu7Meta } from "/opt/build/repo/pages/account.vue?macro=true";
import { default as categories4IxImla18DMeta } from "/opt/build/repo/pages/categories.vue?macro=true";
import { default as edit5slzcgu5XHMeta } from "/opt/build/repo/pages/category/[[id]]/edit.vue?macro=true";
import { default as newguN9QpEY3tMeta } from "/opt/build/repo/pages/category/new.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as entitiesskNq1UqGwWMeta } from "/opt/build/repo/pages/entities.vue?macro=true";
import { default as assessJDaCwh6XYrMeta } from "/opt/build/repo/pages/entity/[[id]]/assess.vue?macro=true";
import { default as editXi5ZVZZ3DKMeta } from "/opt/build/repo/pages/entity/[[id]]/edit.vue?macro=true";
import { default as inquire5o8wdiv1LQMeta } from "/opt/build/repo/pages/entity/[[id]]/inquire.vue?macro=true";
import { default as reportsGQRxmVt5YdMeta } from "/opt/build/repo/pages/entity/[[id]]/reports.vue?macro=true";
import { default as viewRdHLseOUV5Meta } from "/opt/build/repo/pages/entity/[[id]]/view.vue?macro=true";
import { default as new1W72BCOYg3Meta } from "/opt/build/repo/pages/entity/new.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as set_45passwordKLRgnMCIcZMeta } from "/opt/build/repo/pages/set-password.vue?macro=true";
import { default as _91_46_46_46slug_93qaRoZ2FrAbMeta } from "/opt/build/repo/pages/survey/[...slug].vue?macro=true";
import { default as indexDNxDaEMsyBMeta } from "/opt/build/repo/pages/survey/index.vue?macro=true";
import { default as tasksyljVs40UAMMeta } from "/opt/build/repo/pages/tasks.vue?macro=true";
import { default as unauthorizedBrmPFsPVpmMeta } from "/opt/build/repo/pages/unauthorized.vue?macro=true";
import { default as edit2T3yAvHSpdMeta } from "/opt/build/repo/pages/user/[[id]]/edit.vue?macro=true";
import { default as newfMta0hnNp0Meta } from "/opt/build/repo/pages/user/new.vue?macro=true";
import { default as usersHI5FWnuapYMeta } from "/opt/build/repo/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93SsOBVsURfxMeta } from "/opt/build/repo/pages/workshop/[...slug].vue?macro=true";
export default [
  {
    name: accountiPk3eaoYu7Meta?.name ?? "account",
    path: accountiPk3eaoYu7Meta?.path ?? "/account",
    meta: accountiPk3eaoYu7Meta || {},
    alias: accountiPk3eaoYu7Meta?.alias || [],
    redirect: accountiPk3eaoYu7Meta?.redirect,
    component: () => import("/opt/build/repo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: categories4IxImla18DMeta?.name ?? "categories",
    path: categories4IxImla18DMeta?.path ?? "/categories",
    meta: categories4IxImla18DMeta || {},
    alias: categories4IxImla18DMeta?.alias || [],
    redirect: categories4IxImla18DMeta?.redirect,
    component: () => import("/opt/build/repo/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: edit5slzcgu5XHMeta?.name ?? "category-id-edit",
    path: edit5slzcgu5XHMeta?.path ?? "/category/:id?/edit",
    meta: edit5slzcgu5XHMeta || {},
    alias: edit5slzcgu5XHMeta?.alias || [],
    redirect: edit5slzcgu5XHMeta?.redirect,
    component: () => import("/opt/build/repo/pages/category/[[id]]/edit.vue").then(m => m.default || m)
  },
  {
    name: newguN9QpEY3tMeta?.name ?? "category-new",
    path: newguN9QpEY3tMeta?.path ?? "/category/new",
    meta: newguN9QpEY3tMeta || {},
    alias: newguN9QpEY3tMeta?.alias || [],
    redirect: newguN9QpEY3tMeta?.redirect,
    component: () => import("/opt/build/repo/pages/category/new.vue").then(m => m.default || m)
  },
  {
    name: dashboardB0S6bZ6pqpMeta?.name ?? "dashboard",
    path: dashboardB0S6bZ6pqpMeta?.path ?? "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    alias: dashboardB0S6bZ6pqpMeta?.alias || [],
    redirect: dashboardB0S6bZ6pqpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entitiesskNq1UqGwWMeta?.name ?? "entities",
    path: entitiesskNq1UqGwWMeta?.path ?? "/entities",
    meta: entitiesskNq1UqGwWMeta || {},
    alias: entitiesskNq1UqGwWMeta?.alias || [],
    redirect: entitiesskNq1UqGwWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/entities.vue").then(m => m.default || m)
  },
  {
    name: assessJDaCwh6XYrMeta?.name ?? "entity-id-assess",
    path: assessJDaCwh6XYrMeta?.path ?? "/entity/:id?/assess",
    meta: assessJDaCwh6XYrMeta || {},
    alias: assessJDaCwh6XYrMeta?.alias || [],
    redirect: assessJDaCwh6XYrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/entity/[[id]]/assess.vue").then(m => m.default || m)
  },
  {
    name: editXi5ZVZZ3DKMeta?.name ?? "entity-id-edit",
    path: editXi5ZVZZ3DKMeta?.path ?? "/entity/:id?/edit",
    meta: editXi5ZVZZ3DKMeta || {},
    alias: editXi5ZVZZ3DKMeta?.alias || [],
    redirect: editXi5ZVZZ3DKMeta?.redirect,
    component: () => import("/opt/build/repo/pages/entity/[[id]]/edit.vue").then(m => m.default || m)
  },
  {
    name: inquire5o8wdiv1LQMeta?.name ?? "entity-id-inquire",
    path: inquire5o8wdiv1LQMeta?.path ?? "/entity/:id?/inquire",
    meta: inquire5o8wdiv1LQMeta || {},
    alias: inquire5o8wdiv1LQMeta?.alias || [],
    redirect: inquire5o8wdiv1LQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/entity/[[id]]/inquire.vue").then(m => m.default || m)
  },
  {
    name: reportsGQRxmVt5YdMeta?.name ?? "entity-id-reports",
    path: reportsGQRxmVt5YdMeta?.path ?? "/entity/:id?/reports",
    meta: reportsGQRxmVt5YdMeta || {},
    alias: reportsGQRxmVt5YdMeta?.alias || [],
    redirect: reportsGQRxmVt5YdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/entity/[[id]]/reports.vue").then(m => m.default || m)
  },
  {
    name: viewRdHLseOUV5Meta?.name ?? "entity-id-view",
    path: viewRdHLseOUV5Meta?.path ?? "/entity/:id?/view",
    meta: viewRdHLseOUV5Meta || {},
    alias: viewRdHLseOUV5Meta?.alias || [],
    redirect: viewRdHLseOUV5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/entity/[[id]]/view.vue").then(m => m.default || m)
  },
  {
    name: new1W72BCOYg3Meta?.name ?? "entity-new",
    path: new1W72BCOYg3Meta?.path ?? "/entity/new",
    meta: new1W72BCOYg3Meta || {},
    alias: new1W72BCOYg3Meta?.alias || [],
    redirect: new1W72BCOYg3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/entity/new.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password6Wrw3WyRqiMeta?.name ?? "forgot-password",
    path: forgot_45password6Wrw3WyRqiMeta?.path ?? "/forgot-password",
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    alias: forgot_45password6Wrw3WyRqiMeta?.alias || [],
    redirect: forgot_45password6Wrw3WyRqiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45password4iBG212Ge4Meta?.name ?? "reset-password",
    path: reset_45password4iBG212Ge4Meta?.path ?? "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    alias: reset_45password4iBG212Ge4Meta?.alias || [],
    redirect: reset_45password4iBG212Ge4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45passwordKLRgnMCIcZMeta?.name ?? "set-password",
    path: set_45passwordKLRgnMCIcZMeta?.path ?? "/set-password",
    meta: set_45passwordKLRgnMCIcZMeta || {},
    alias: set_45passwordKLRgnMCIcZMeta?.alias || [],
    redirect: set_45passwordKLRgnMCIcZMeta?.redirect,
    component: () => import("/opt/build/repo/pages/set-password.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93qaRoZ2FrAbMeta?.name ?? "survey-slug",
    path: _91_46_46_46slug_93qaRoZ2FrAbMeta?.path ?? "/survey/:slug(.*)*",
    meta: _91_46_46_46slug_93qaRoZ2FrAbMeta || {},
    alias: _91_46_46_46slug_93qaRoZ2FrAbMeta?.alias || [],
    redirect: _91_46_46_46slug_93qaRoZ2FrAbMeta?.redirect,
    component: () => import("/opt/build/repo/pages/survey/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexDNxDaEMsyBMeta?.name ?? "survey",
    path: indexDNxDaEMsyBMeta?.path ?? "/survey",
    meta: indexDNxDaEMsyBMeta || {},
    alias: indexDNxDaEMsyBMeta?.alias || [],
    redirect: indexDNxDaEMsyBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/survey/index.vue").then(m => m.default || m)
  },
  {
    name: tasksyljVs40UAMMeta?.name ?? "tasks",
    path: tasksyljVs40UAMMeta?.path ?? "/tasks",
    meta: tasksyljVs40UAMMeta || {},
    alias: tasksyljVs40UAMMeta?.alias || [],
    redirect: tasksyljVs40UAMMeta?.redirect,
    component: () => import("/opt/build/repo/pages/tasks.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedBrmPFsPVpmMeta?.name ?? "unauthorized",
    path: unauthorizedBrmPFsPVpmMeta?.path ?? "/unauthorized",
    meta: unauthorizedBrmPFsPVpmMeta || {},
    alias: unauthorizedBrmPFsPVpmMeta?.alias || [],
    redirect: unauthorizedBrmPFsPVpmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: edit2T3yAvHSpdMeta?.name ?? "user-id-edit",
    path: edit2T3yAvHSpdMeta?.path ?? "/user/:id?/edit",
    meta: edit2T3yAvHSpdMeta || {},
    alias: edit2T3yAvHSpdMeta?.alias || [],
    redirect: edit2T3yAvHSpdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/user/[[id]]/edit.vue").then(m => m.default || m)
  },
  {
    name: newfMta0hnNp0Meta?.name ?? "user-new",
    path: newfMta0hnNp0Meta?.path ?? "/user/new",
    meta: newfMta0hnNp0Meta || {},
    alias: newfMta0hnNp0Meta?.alias || [],
    redirect: newfMta0hnNp0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/user/new.vue").then(m => m.default || m)
  },
  {
    name: usersHI5FWnuapYMeta?.name ?? "users",
    path: usersHI5FWnuapYMeta?.path ?? "/users",
    meta: usersHI5FWnuapYMeta || {},
    alias: usersHI5FWnuapYMeta?.alias || [],
    redirect: usersHI5FWnuapYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/users.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93SsOBVsURfxMeta?.name ?? "workshop-slug",
    path: _91_46_46_46slug_93SsOBVsURfxMeta?.path ?? "/workshop/:slug(.*)*",
    meta: _91_46_46_46slug_93SsOBVsURfxMeta || {},
    alias: _91_46_46_46slug_93SsOBVsURfxMeta?.alias || [],
    redirect: _91_46_46_46slug_93SsOBVsURfxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/workshop/[...slug].vue").then(m => m.default || m)
  }
]