export default defineAppConfig({
  ui: {
    primary: "green",
    gray: "zinc",
    input: {
      rounded: "rounded-xl",
      color: {
        white: {
          outline:
            "shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-2 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400",
        },
      },
      default: {
        size: "lg",
        color: "white",
        variant: "outline",
        loadingIcon: "i-heroicons-arrow-path-20-solid",
      },
    },
    select: {
      rounded: "rounded-xl",
      color: {
        white: {
          outline:
            "shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-2 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400",
        },
      },
      default: {
        size: "lg",
        color: "white",
        variant: "outline",
        loadingIcon: "i-heroicons-arrow-path-20-solid",
        trailingIcon: "i-heroicons-chevron-down-20-solid",
      },
    },
    notifications: {
      container:
        "flex justify-center px-4 sm:px-6 py-6 space-y-3 overflow-y-auto flex-col items-center",
      position: "bottom-0 top-auto",
      width: "w-full sm:w-full w-dvw justify-center",
    },
    notification: {
      padding: "p-2",
      inner: "w-fit flex-1",
      wrapper: "w-fit pointer-events-auto",
      title: "text-xs text-center font-regular text-white",
      container: "relative overflow-hidden bg-primary-500",
      default: {
        color: "green",
        icon: null,
        timeout: 3000,
        closeButton: {
          icon: "i-heroicons-x-mark-20-solid text-white",
          color: "white",
          variant: "link",
          padded: false,
        },
        actionButton: {
          size: "xs",
          color: "white",
        },
      },
    },
    button: {
      rounded: "rounded-full",
      padding: {
        lg: "px-4 py-2.5",
      },
      color: {
        gray: {
          solid:
            "shadow-sm text-gray-700 dark:text-gray-200 bg-gray-100 hover:bg-gray-100 disabled:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700/50 dark:disabled:bg-gray-800 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        },
      },
      default: {
        size: "lg",
      },
    },
    divider: {
      wrapper: {
        horizontal: "py-5 w-full flex-row",
      },
    },
  },
});
